import { Button } from "components/atoms/button/button";
import Image from "next/legacy/image";
import React from "react";
import { PriceInfoCardWrapper } from "./price-info-card.styles";
import WhatAppIcon from "public/assets/whatsappicon.png";

interface IPriceInfoCardProps {
  onCTAClick: () => void;
  ctaTitle?: string;
  heading?: string;
}

export const PriceInfoCard: React.FC<IPriceInfoCardProps> = ({
  onCTAClick,
  ctaTitle
}) => {
  return (
    <PriceInfoCardWrapper>
      <Button
        variant="primary"
        isFullWidth
        onClick={onCTAClick}
        data-testid="info-bimbel-banner-button"
      >
        {ctaTitle}
        <Image
          src={WhatAppIcon}
          alt="Whatsapp Icon"
          layout="fixed"
          width={24}
          height={24}
          style={{ marginBottom: "2.5px", marginLeft: "2px" }}
        />
      </Button>
    </PriceInfoCardWrapper>
  );
};
