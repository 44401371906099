import styled from "styled-components";
import { breakpoints } from "styles";
export const PriceInfoCardWrapper = styled.div`
  justify-items: left;
  p {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.fontSizes.md};
    line-height: 20px;
    margin: 4px 0;
  }
  button {
    background: #1e5af6;
    text-align: center;
    margin-top: 4%;
    color: white;
    width: 224px;
    box-shadow: 0px 0px 7.12386px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    max-width: 400px;
  }

  @media ${breakpoints.mobile} {
    justify-items: center;
    button {
      font-size: 14px;
      padding: 10px 12px;
      max-width: 100%;
    }
  }
`;
