import styled from "styled-components";
import { breakpoints } from "styles";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  border-radius: 15px;
  padding: 35px;
  @media ${breakpoints.mobile} {
    width: 300px;
    height: 180px;
  }
`;

export const Modal = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  border-radius: 15px;
  padding: 4px;
  @media ${breakpoints.mobile} {
    width: 300px;
    height: 180px;
    iframe {
      width: 300px;
      height: 200px;
    }
  }
`;

export const CloseButton = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 504px;
  width: 896px;
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 10, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
