import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";

const TEXT_CONTAINER_HEIGHT = `${590 / 2.53}px`;

export const LeadGenerationSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    circle,
    rgba(21, 69, 211, 0.7) 0%,
    rgba(26, 88, 241, 1) 30%,
    rgba(25, 72, 205, 1) 100%
  );
  overflow: hidden;
`;

export const LeadGenerationSectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 1280px;
  overflow: hidden;
  gap: ${rem(32)};
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 0px 16px 16px 16px;
    width: 100%;
    max-width: none;
    gap: 0px;
    align-items: center;
  }
`;

export const LeadGenerationImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media ${breakpoints.mobile} {
    max-width: 100%;
  }
`;

export const LeadGenerationTextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  position: relative;
  padding: ${rem(48)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  max-width: 590px;
  height: calc(590px / 3);
  margin: 27px 0px 27px 0px;
  @media ${breakpoints.mobile} {
    padding: 16px;
    box-sizing: border-box;
    width: 370px;
    margin: 0px;
    height: 300px;
  }
  @media ${breakpoints.laptop} {
    flex-basis: 45%;
  }
`;

export const SpacerForAbsolute = styled.div<MainContentProps>`
  ${({ page }) =>
    page === "bimbelPage"
      ? `
      @media ${breakpoints.mobile} {
      height: ${TEXT_CONTAINER_HEIGHT};
      display: block;
  }
  `
      : ""}
`;

interface MainContentProps {
  page: string;
}

export const MainContent = styled.div<MainContentProps>`
  ${({ page }) =>
    page === "bimbelPage"
      ? `
    @media ${breakpoints.mobile} {
      position: absolute;
      top: calc(68vw);
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
      : ""}
`;

export const LeadGenerationTextContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  min-width: fit-content;
  width: 590px;
  height: ${TEXT_CONTAINER_HEIGHT};
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 50px;
  @media ${breakpoints.mobile} {
    max-width: 100%;
    justify-content: flex-start;
    text-align: center;
    gap: 0px;
  }
`;

export const LeadGenerationSubheading = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  line-height: 36px;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  color: ${({ theme }) => theme.colors.black["100"]};
  margin: 0;
  @media ${breakpoints.mobile} {
    max-width: 100%;
    justify-content: flex-start;
    text-align: center;
    gap: 0px;
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }
`;

export const LeadGenerationHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSizes["5xl"]};
  color: ${({ theme }) => theme.colors.blue["800"]};
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  line-height: 58px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;
  text-shadow: 3px 3px 4px rgba(0, 18, 65, 0.14);
  @media ${breakpoints.mobile} {
    max-width: 100%;
    justify-content: flex-start;
    text-align: center;
    gap: 0px;
    font-size: ${rem(28)};
    margin: 0px 0px 0px 20px;
    line-height: 36px;
  }
`;

export const LeadGenerationPriceButtonWrapper = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LeadGenerationTagContainer = styled.div`
  display: flex;
  justify-content: left;
  min-width: fit-content;
  margin-top: 5px;
  flex-direction: row; /* Arrange Tag1 and Tag2 in a single line by default */
  gap: 5px; /* Space between Tag1 and Tag2 */
  @media ${breakpoints.mobile} {
    flex-direction: column; /* Stack Tag1 and Tag2 vertically in mobile view */
    gap: 12px; /* Adjust spacing for mobile view */
    align-items: center; /* Center-align container in mobile view */
  }
`;

export const LeadGenerationTag = styled.div`
  padding: 6px 9px;
  color: ${({ theme }) => theme.colors.brand[600]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  font-weight: 700;
  min-width: fit-content;
  border-radius: 25.87px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.md};
`;
export const Tag1 = styled.div`
  display: flex; /* Arrange LeadGenerationTags in a single line */
  gap: 8px; /* Spacing between tags */
  justify-content: flex-start; /* Align to the left */
  @media ${breakpoints.mobile} {
    justify-content: center; /* Center-align tags in mobile view */
  }
`;
export const Tag2 = styled.div`
  display: flex; /* Arrange tags in a single line if needed */
  justify-content: center; /* Center-align the tag */
  @media ${breakpoints.mobile} {
    justify-content: center; /* Ensure centering in mobile view */
  }
`;
