import React, { useState } from "react";
import Image from "next/image";
import CloseButtonIcon from "public/assets/close-button.png";
import { IVideoModalProps } from "./video-modal.types";
import {
  ModalOverlay,
  ModalWrapper,
  Modal,
  CloseButton,
  SpinnerWrapper,
  Spinner
} from "./video-modal.styles";

export const VideoModal: React.FC<IVideoModalProps> = ({
  onClose,
  videoUrl
}) => {
  const [loading, setLoading] = useState(true);

  const extractVideoId = (url: string) => {
    const youtubeMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
    );
    if (youtubeMatch) {
      return { type: "youtube", id: youtubeMatch[1] };
    }

    const driveMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?drive\.google\.com\/file\/d\/([^\/]+)/
    );
    if (driveMatch) {
      return { type: "drive", id: driveMatch[1] };
    }

    return { type: "unknown", id: url };
  };

  const handleCloseClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onClose();
  };

  const videoData = extractVideoId(videoUrl);
  const videoSrc =
    videoData.type === "youtube"
      ? `https://www.youtube.com/embed/${videoData.id}?autoplay=1&modestbranding=1&showinfo=0&controls=1&rel=0`
      : videoData.type === "drive"
        ? `https://drive.google.com/file/d/${videoData.id}/preview`
        : videoUrl;

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton onClick={handleCloseClick}>
          <Image src={CloseButtonIcon} alt="Close" width={24} height={24} />
        </CloseButton>
        <Modal>
          {loading && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
          <iframe
            src={videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            width={896}
            height={504}
            style={{
              borderRadius: "10px",
              display: loading ? "none" : "block"
            }}
            onLoad={() => setLoading(false)}
          />
        </Modal>
      </ModalWrapper>
    </ModalOverlay>
  );
};
